import * as React from 'react';

import { CONVERSATION_VISIBILITY } from 'types/interfaces';

import { CardContainer, H4, InlineWarning, P, SelectionBlocks } from '@driftt/tide-core';

const CONVERSATION_VISIBILITY_OPTIONS = [
  {
    value: CONVERSATION_VISIBILITY.PUBLIC,
    title: 'Everyone in my organization',
    description: `Allowing everyone in your organization to see conversations started on your videos will make sure that none slip through the cracks if you can't get to them in time.`,
  },
  {
    value: CONVERSATION_VISIBILITY.PARTICIPANTS_ONLY,
    title: 'Only current participants of the conversation',
    description:
      'Messages sent on your videos will only be visible in the inbox to you and anyone you invite to the conversation. If you are the only participant and are unable to reply, nothing will happen.',
  },
];

interface ConversationPrivacyProps {
  conversationVisibility: CONVERSATION_VISIBILITY;
  updateUserCustomizationsWrapper: ({
    conversationVisibility,
  }: {
    conversationVisibility: CONVERSATION_VISIBILITY;
  }) => void;
}

const ConversationPrivacy = ({
  conversationVisibility,
  updateUserCustomizationsWrapper,
}: ConversationPrivacyProps) => (
  <CardContainer padding="large">
    <H4>Conversation privacy</H4>
    <P>By default, who can see the conversations on my videos?</P>
    <SelectionBlocks
      type="radio"
      options={CONVERSATION_VISIBILITY_OPTIONS}
      selected={[conversationVisibility]}
      onChange={([newConversationVisibility]) =>
        updateUserCustomizationsWrapper({
          conversationVisibility: newConversationVisibility,
        })
      }
    />
    <InlineWarning
      type="warning"
      copy="Changing this setting will apply only to new conversations started after the change."
    />
  </CardContainer>
);

export default ConversationPrivacy;
