/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { useContext } from 'react';

import { DriftUserData } from 'types/interfaces';

import { A, CardContainer, CircleLoader, H3, NavigationHeader } from '@driftt/tide-core';

import { refreshUser } from 'api';
import { UserContext } from 'context/UserContext';
import { openTeamsUpgradePlaybook } from 'utils';

if (window.driftBilling === undefined) {
  class DriftBilling {
    cardInfo: any;
    static iframeId = 'drift-billing-iframe';

    constructor() {
      window.addEventListener('message', this.handleMessage, false);
      this.cardInfo = null;
    }

    refreshAccount = async () => {
      const response = await refreshUser();
      if (response.status === 200) {
        window.location.reload();
      }
    };

    handleMessage = ({ data: message }) => {
      try {
        const { type, data } = JSON.parse(message);
        if (!type.startsWith('drift-billing')) {
          return;
        }
        switch (type) {
          case 'drift-billing-close':
            this.close();
            break;
          case 'drift-billing-complete':
            break;
          case 'drift-billing-error':
            break;
          case 'drift-billing-pending':
            break;
          case 'drift-billing-submit':
            this.close();
            this.cardInfo = data;
            this.refreshAccount();
            break;
        }
      } catch (e) {
        return;
      }
    };

    open = () => {
      const billingIframe = document.getElementById(DriftBilling.iframeId);
      billingIframe && (billingIframe.style.display = 'block');
    };

    close = () => {
      const billingIframe = document.getElementById(DriftBilling.iframeId);
      billingIframe && (billingIframe.style.display = 'none');
    };
  }
  window.driftBilling = new DriftBilling();
}

const generatePlanName = (userData: DriftUserData) => {
  if (userData?.hasDriftVideoTeamTrial) {
    return 'Drift Video Team (Trial)';
  }
  if (userData?.hasDriftVideoTrial) {
    return 'Drift Video Pro (Trial)';
  }
  if (userData?.hasPaidVideo) {
    return 'Drift Video Pro';
  }
  return 'Drift Video Free';
};

const UpgradeSection = ({ userData }: { userData: DriftUserData }) => {
  if (userData?.hasTouchlessVideoUpgrade) {
    return <A onClick={openTeamsUpgradePlaybook}>Chat with your account manager to upgrade</A>;
  }
  if (userData?.hasTeamManagement) {
    return (
      <a className="team-link" onClick={openTeamsUpgradePlaybook}>
        Chat with your account manager to upgrade
      </a>
    );
  }
  return <div className="team-link">Contact your admin to upgrade to Drift Video Pro</div>;
};

const Billing = () => {
  const { user } = useContext(UserContext);
  const userData = user?.driftUserData as DriftUserData;

  return (
    <>
      <NavigationHeader title="Billing" />
      <CardContainer padding="large">
        {user ? (
          <>
            <H3>{generatePlanName(userData)}</H3>
            {!userData?.hasPaidVideo && <UpgradeSection userData={userData} />}
            {userData?.canInviteTeammates && (
              <div>
                <a
                  className="team-link"
                  href="https://app.drift.com/settings2/team"
                  target="_blank"
                  rel="noopener noreferrer">
                  Invite teammates
                </a>
                <div className="info-section">
                  <span aria-label="point-right" role="img">
                    👉
                  </span>{' '}
                  Note: You will be taken to Drift to change your settings
                </div>
              </div>
            )}
          </>
        ) : (
          <CircleLoader />
        )}
      </CardContainer>
    </>
  );
};

export default Billing;
