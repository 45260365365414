import outReachLogo from 'assets/images/outreach-logo.png';
import salesForceLogo from 'assets/images/sfdc-logo.webp';
import zoomLogo from 'assets/images/zoom-logo.png';

import { CHAT_APP_URL } from 'utils';

export const IntegrationListInfo = [
  {
    name: 'Zoom',
    urlPath: 'zoom',
    cardDescription:
      'Turn Zoom meetings into shareable content by automatically syncing them to your Drift Video library.',
    description:
      'The Zoom integration automatically uploads recorded meetings to your Drift Video library. Then, users can edit, share, and track views. Note: Zoom Meetings must be recorded to the cloud.',
    logoSrc: zoomLogo,
    isEnabled: (integrationStates) => {
      return integrationStates.zoom;
    },
    helpdocSrc: 'https://gethelp.drift.com/s/article/Connecting-Drift-Video-with-your-Zoom-account',
    disableLink: 'https://marketplace.zoom.us/user/installed',
    enableLink: '/accounts/zoom/login/?process=connect',
    externalConnect: true,
  },
  {
    name: 'Salesforce',
    urlPath: CHAT_APP_URL + '/settings/setup/salesforce',
    cardDescription: 'Sync your Drift Video leads and meetings to Salesforce.',
    logoSrc: salesForceLogo,
    isEnabled: (integrationStates) => {
      return integrationStates.salesforce;
    },
    featureGate: 'SALESFORCE_INTEGRATION',
    externalSetup: true,
  },
  {
    name: 'Outreach',
    urlPath: 'outreach',
    cardDescription: 'Easily sync your Drift video data with Outreach.',
    description:
      'The Outreach integration allows for easy sending and tracking of Drift Videos sent through Outreach.',
    logoSrc: outReachLogo,
    isEnabled: (integrationStates) => {
      return integrationStates.outreach;
    },
    featureGate: 'OUTREACH_INTEGRATION',
    helpdocSrc: 'https://help.salesloft.com/s/article/How-Do-I-Reset-My-Drift-Video-Password',
    disableLink: CHAT_APP_URL + '/settings/setup/outreach',
    enableLink: `https://accounts.outreach.io/oauth/authorize?client_id=08745ef78bd1fbe782f7b8317fd988201da78385ac3761d2dfe2d33a566f7c22&redirect_uri=${CHAT_APP_URL}accept-integration/outreach&response_type=code&scope=prospects.all sequences.all sequenceStates.all mailboxes.all sequenceSteps.all snippets.all templates.all events.all prospects.all`,
    externalConnect: true,
  },
];
