import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import * as S3 from 'aws-sdk/clients/s3';

import {
  Breadcrumbs,
  CardContainer,
  CircleLoader,
  InlineEditInput,
  InlineWarning,
  Input,
  Label,
  NavigationHeader,
  Ribbon,
  Select,
} from '@driftt/tide-core';
import { UploadArea } from '@driftt/tide-file-upload';

import {
  createOrUpdateTeamCustomizations,
  createOrUpdateUserCustomizations,
  fetchLogoUploadInfo,
  fetchUserCustomizationsInternal,
  uploadToS3,
} from 'api';
import { UserContext } from 'context/UserContext';
import { openTeamsUpgradePlaybook } from 'utils';

import { MAX_LOGO_SIZE } from './constants.js';
import './style.css';

const CustomBranding: React.FC = () => {
  const { user, hasUnifiedSeatExperience, userHasFeature } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [hadError, setHadError] = useState(false);
  const [warning, setWarning] = useState('');
  const [showUpgradeState, setShowUpgradeState] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [website, setWebsite] = useState<string | null>(null);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (user) {
      setShowUpgradeState(!user.driftUserData.hasVideoCustomization);

      const fetchData = async () => {
        try {
          const { logoUrl, website, darkMode } = await fetchUserCustomizationsInternal(user.id);

          setLogoUrl(logoUrl);
          setWebsite(website);
          setDarkMode(darkMode);
          setLoading(false);
        } catch (err) {
          setHadError(true);
          Error((err as any).message);
        }
      };

      fetchData();
    }
  }, [user]);

  // For users with org-wide custom branding feature, update team instead of user
  const updateCustomizations = async (update: object) => {
    try {
      return userHasFeature('ORG_WIDE_CUSTOM_BRANDING')
        ? await createOrUpdateTeamCustomizations(update)
        : await createOrUpdateUserCustomizations(update);
    } catch (err) {
      setHadError(true);
      Error((err as any).message);
    }
  };

  const handleUploadFile = async (file) => {
    try {
      const fileExtension = file.name.split('.').pop();
      const { accessKey, bucket, key, secretKey, sessionToken } = await fetchLogoUploadInfo(
        fileExtension,
      );

      await uploadToS3({
        S3,
        accessKey,
        bucket,
        file,
        key,
        secretKey,
        sessionToken,
        onProgress: () => {},
      });

      const { logoUrl } = await updateCustomizations({ logo: key });

      return { downloadUrl: logoUrl };
    } catch (err) {
      setHadError(true);
      Error((err as any).message);
    }
  };

  const handleChangeWebsite = (website) => {
    updateCustomizations({ website });
    setWebsite(website);
  };

  const handleChangeDarkMode = (darkMode) => {
    updateCustomizations({ darkMode });
    setDarkMode(darkMode);
  };

  return (
    <div className="custom-branding">
      <NavigationHeader
        title="Custom branding"
        secondary={
          hasUnifiedSeatExperience && (
            <Breadcrumbs
              headings={[
                {
                  title: 'Video',
                  link: '/video',
                },
                {
                  title: 'Custom branding',
                  link: '/settings/branding',
                  isActive: true,
                },
              ]}
            />
          )
        }
      />
      <CardContainer padding="large">
        {hadError ? (
          'Sorry, an error occurred.'
        ) : loading ? (
          <div className="custom-branding__loading">
            <CircleLoader size="large" />
          </div>
        ) : (
          <article>
            {showUpgradeState && (
              <div className="custom-branding__upgrade">
                <button onClick={openTeamsUpgradePlaybook}>
                  <Ribbon isUpgrade={true} text="upgrade" />
                </button>
              </div>
            )}
            <section>
              <Label>Company logo</Label>
              <UploadArea
                disabled={showUpgradeState}
                type="image"
                previewUrl={logoUrl}
                maxFileSize={MAX_LOGO_SIZE}
                handleUploadFile={(file) => {
                  setWarning('');
                  return handleUploadFile(file);
                }}
                handleSaveFile={(file) => setLogoUrl(file)}
                onReadError={(err) => {
                  setWarning('Error reading file');
                  Error((err as any).message);
                }}
                onTypeError={() => setWarning('File extension not supported')}
                onSizeError={() => setWarning('File exceeds maximum file size of 3MB')}
              />
              {warning && <InlineWarning type="warning" copy={warning} />}
            </section>
            <section>
              <Label>Company URL</Label>
              <div>
                {showUpgradeState ? (
                  <Input prefixText="https://" value={website} disabled />
                ) : (
                  <InlineEditInput
                    type="link"
                    leadingText="https://"
                    withSave
                    shouldValidate
                    key={website}
                    value={website}
                    onSubmit={handleChangeWebsite}
                  />
                )}
              </div>
            </section>
            <section>
              <Select
                disabled={showUpgradeState}
                label="Color mode"
                clearable={false}
                value={darkMode}
                onChange={({ value }) => handleChangeDarkMode(value)}
                options={[
                  {
                    label: 'Dark mode',
                    value: true,
                  },
                  {
                    label: 'Light mode',
                    value: false,
                  },
                ]}
              />
            </section>
          </article>
        )}
      </CardContainer>
    </div>
  );
};

export default CustomBranding;
