import * as React from 'react';

import { ReactComponent as DriftBot } from 'assets/svg/driftbot.svg';

import { Div, P } from '@driftt/tide-core';

import './styles.css';

const BotPreview: React.FC<{ message: string; showBookMeetingButton: boolean }> = ({
  message,
  showBookMeetingButton,
}) => {
  return (
    <Div className="bot-preview">
      <Div className="drift-bot-avatar">
        <DriftBot />
      </Div>
      <Div className="conversation-preview">
        <Div className="name">DriftBot</Div>
        <Div className="chat-bubble greeting">
          <div dangerouslySetInnerHTML={{ __html: message }}></div>
        </Div>
        <Div className="chat-bubble action-buttons">
          <P>Anything I can do to help?</P>
          <Div className="action-button">
            <span role="img" aria-label="tv-emoji">
              📺
            </span>
            Nope, just watching
          </Div>
          <Div className="action-button">
            <span role="img" aria-label="chat-emoji">
              💬
            </span>
            I have a question
          </Div>
          {showBookMeetingButton && (
            <Div className="action-button">
              <span role="img" aria-label="meeting-emoji">
                🗓
              </span>
              Book a meeting
            </Div>
          )}
        </Div>
        <Div className="customer-preview">
          <P>This is a customer preview</P>
        </Div>
      </Div>
    </Div>
  );
};

export default BotPreview;
