import * as React from 'react';
import { useContext } from 'react';

import { H5, P, Toggle } from '@driftt/tide-core';

import { UserCustomizationsContext } from 'context/UserCustomizationContext';
import useConfirmation from 'hooks/useConfirmation';

const DisableCaptions = () => {
  const { userCustomization, handleUserCustomizationChange } =
    useContext(UserCustomizationsContext);
  const [confirmDisableCaptions, ConfirmDisableCaptionsModal] = useConfirmation();

  return (
    <div>
      <div className="toggle-wrapper">
        <Toggle
          checked={!userCustomization?.disableCaptions}
          onChange={async (e) => {
            if (!e.target.checked) {
              const confirmed = await confirmDisableCaptions();

              if (confirmed) {
                handleUserCustomizationChange({ disableCaptions: true });
              }
            } else {
              handleUserCustomizationChange({ disableCaptions: false });
            }
          }}
        />
        <H5>Enable captions for all my videos</H5>
      </div>
      <P>Captions are a proven method of engagement and make videos accessible to all viewers.</P>
      <ConfirmDisableCaptionsModal
        type="warning"
        size="small"
        title="Disable captions for all videos"
        confirmButtonText="Disable captions">
        <P>
          Captions will be removed from your existing videos and automatically disabled for future
          videos
        </P>
      </ConfirmDisableCaptionsModal>
    </div>
  );
};

export default DisableCaptions;
