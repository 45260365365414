/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';

import zoomMMAsset from 'assets/images/zoom-mm-asset.png';
import PropTypes from 'prop-types';

import {
  A,
  Breadcrumbs,
  Button,
  CardContainer,
  Div,
  Footer,
  H1,
  H3,
  Icon,
  Modal,
  NavigationHeader,
  P,
} from '@driftt/tide-core';

import { fetchIntegrationStates, fetchZoomIntegrationStatus } from 'api';
import { UserContext } from 'context/UserContext';
import { segmentPage, segmentTrack } from 'utils/metrics';
import { VIDEO_APP_URL } from 'utils/settingsUtils';

import { IntegrationListInfo } from './constants.js';

import './styles.css';

function IntegrationDetailComponent({ integration, integrationStates }) {
  const history = useHistory();
  const integrationInfo = IntegrationListInfo.find((item) => item.urlPath === integration);
  const isEnabled = integrationInfo.isEnabled(integrationStates);

  const handleIntegrationDetailTrack = (cta) => {
    segmentTrack('Integration Detail CTA Clicked', {
      integration: integrationInfo.name,
      isEnabled,
      buttonCta: cta,
    });
  };

  const handleBackClick = () => {
    handleIntegrationDetailTrack('Integration list');
    history.push('/video/settings/integrations');
  };

  return (
    <CardContainer className="integration-detail-container">
      <Div className="left-col">
        <img className="integration-logo" alt="integration-logo" src={integrationInfo.logoSrc} />
        {isEnabled ? (
          <A href={integrationInfo.disableLink} target="_blank">
            <Button type="tertiary" onClick={() => handleIntegrationDetailTrack('Disable')}>
              Disable {integrationInfo.name}
            </Button>
          </A>
        ) : (
          <Button
            onClick={() => {
              handleIntegrationDetailTrack('Enabled');

              if (integrationInfo.externalConnect) {
                const windowName = 'ConnectWithOauth';
                const windowOptions = 'location=0,status=0,width=800,height=600';
                const onClose = () => {
                  window.location.reload;
                };
                const _oauthWindow = window.open(
                  VIDEO_APP_URL + integrationInfo.enableLink,
                  windowName,
                  windowOptions,
                );
                const _oauthInterval = window.setInterval(() => {
                  if (_oauthWindow.closed) {
                    window.clearInterval(_oauthInterval);
                    onClose();
                  }
                }, 1000);
              } else {
                window.location = integrationInfo.enableLink;
              }
            }}
          >
            Enable {integrationInfo.name}
          </Button>
        )}
        <Button onClick={handleBackClick} type="utility">
          See all integrations
        </Button>
      </Div>
      <Div className="right-col">
        <Div className="header-container">
          <H1>{integrationInfo.name}</H1>
          {isEnabled ? (
            <span className="integration-status connected">
              <Icon name="check-circle" height="16" />
              Connected
            </span>
          ) : (
            <span className="integration-status not-connected">
              <Icon name="warning-circle" height="16" />
              Not connected
            </span>
          )}
        </Div>
        <P>{integrationInfo.description}</P>
        {!!integrationInfo.helpdocSrc && (
          <P>
            Have questions or need help getting set up? Visit our&nbsp;
            <A
              href={integrationInfo.helpdocSrc}
              target="_blank"
              onClick={() => handleIntegrationDetailTrack('Helpdoc')}
            >
              helpdoc
            </A>
            &nbsp;or chat in if you need help!
          </P>
        )}
      </Div>
    </CardContainer>
  );
}

function IntegrationsList({ integrationStates, loading }) {
  const history = useHistory();
  const { userHasFeature } = useContext(UserContext);

  const handleCardClick = (integrationInfo) => {
    segmentTrack('Integration Card Clicked', {
      integration: integrationInfo.name,
    });
    if (integrationInfo.externalSetup) {
      window.open(integrationInfo.urlPath);
    } else {
      history.push(`/video/settings/integrations/${integrationInfo.urlPath}`);
    }
  };

  return (
    <Div className="integrations-list-container">
      {IntegrationListInfo.map((integrationInfo, i) => {
        if (!integrationInfo.featureGate || userHasFeature(integrationInfo.featureGate)) {
          return (
            <Div
              className="integrations-card-wrapper"
              key={`integration-component-${i}-${integrationInfo.urlPath}`}
              onClick={() => handleCardClick(integrationInfo)}
            >
              <CardContainer className="integrations-card" padding="medium">
                <img
                  className="integration-logo"
                  alt={`integration-logo-${integrationInfo.name}`}
                  src={integrationInfo.logoSrc}
                />
                <H1 className="integration-card-header">{integrationInfo.name}</H1>
                <P className="integration-description">{integrationInfo.cardDescription}</P>
                {!loading &&
                  (integrationInfo.isEnabled(integrationStates) ? (
                    <span className="integration-status connected">
                      <Icon name="check-circle" height="16" />
                      Connected
                    </span>
                  ) : (
                    <span className="integration-status not-connected">
                      <Icon name="warning-circle" height="16" />
                      Not connected
                    </span>
                  ))}
              </CardContainer>
            </Div>
          );
        }
        return null;
      })}
    </Div>
  );
}

function IntegrationSettings() {
  const { createUrlPath, hasUnifiedSeatExperience } = useContext(UserContext);
  const [educationModalState, setEducationModalState] = useState(
    window.location.search.includes('enabled'),
  );
  const [loading, setLoading] = useState(true);
  const [integrationStates, setIntegrationStates] = useState({});

  useEffect(() => {
    if (loading) {
      Promise.all([fetchZoomIntegrationStatus(), fetchIntegrationStates()]).then(
        ([zoomData, states]) => {
          setIntegrationStates({ ...states, zoom: zoomData.data });
          setLoading(false);
        },
      );
    }
  }, [loading]);

  segmentPage('Video Integrations Settings');

  const handleIntegrationSuccessModalEvent = (cta) => {
    segmentTrack('Integration Connected Modal Clicked', {
      integration: 'zoom',
      buttonCta: cta,
    });
  };

  if (educationModalState) {
    segmentTrack('Integration Connected Modal Viewed', { integration: 'zoom' });
  }

  return (
    <div className="account-settings-card">
      <NavigationHeader
        title="Integrations"
        secondary={
          hasUnifiedSeatExperience && (
            <Breadcrumbs
              headings={[
                {
                  title: 'Video',
                  link: '/video',
                },
                {
                  title: 'Integrations',
                  link: '/settings/integrations',
                  isActive: true,
                },
              ]}
            />
          )
        }
      />
      <Modal
        title="Zoom integration connected"
        open={educationModalState}
        onClose={() => {
          handleIntegrationSuccessModalEvent('Closed');
          setEducationModalState(false);
        }}
        size="medium"
      >
        <img className="modal-asset" alt="model-asset" src={zoomMMAsset} />
        <H3 className="modal-subheader">Your Zoom integration is all set up!</H3>
        <P>
          Meetings recorded to the cloud will automatically sync with Drift Video. Click the button
          below to see where the videos will end up!
        </P>
        <Footer>
          <a href={createUrlPath('/video/?source_type=3')}>
            <Button
              type="primary"
              onClick={() => handleIntegrationSuccessModalEvent('Viewed library')}
            >
              View my Zoom Recordings
            </Button>
          </a>
          <Button
            type="tertiary"
            onClick={() => {
              handleIntegrationSuccessModalEvent('Dismissed');
              setEducationModalState(false);
            }}
          >
            Not now
          </Button>
        </Footer>
      </Modal>
      <Router basename={createUrlPath('/')}>
        <Switch>
          <Route path="/video/settings/integrations/zoom">
            <IntegrationDetailComponent
              integration={'zoom'}
              integrationStates={integrationStates}
            />
          </Route>
          <Route path="/video/settings/integrations/outreach">
            <IntegrationDetailComponent
              integration={'outreach'}
              integrationStates={integrationStates}
            />
          </Route>
          <Route path="/video/settings/integrations">
            <IntegrationsList integrationStates={integrationStates} loading={loading} />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

IntegrationDetailComponent.propTypes = {
  integration: PropTypes.object,
  integrationStates: PropTypes.object,
};

IntegrationsList.propTypes = {
  integrationStates: PropTypes.object,
  loading: PropTypes.bool,
};

export default IntegrationSettings;
