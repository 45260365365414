import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import { CONVERSATION_VISIBILITY } from 'types/interfaces';

import { Breadcrumbs, Layout, NavigationHeader } from '@driftt/tide-core';
import { access } from '@driftt/traffic-control';

import { UserContext } from 'context/UserContext';

import BotMessageEditor from './BotMessageEditor';
import ConversationPrivacy from './ConversationPrivacy';

import './styles.css';

interface ConversationSettingsProps {
  conversationVisibility: CONVERSATION_VISIBILITY;
  updateUserCustomizationsWrapper: ({
    conversationVisibility,
  }: {
    conversationVisibility: CONVERSATION_VISIBILITY;
  }) => void;
  hasBotEditing: boolean;
}

const ConversationSettings = ({
  conversationVisibility = CONVERSATION_VISIBILITY.PUBLIC,
  updateUserCustomizationsWrapper,
  hasBotEditing,
}: ConversationSettingsProps) => {
  const { hasUnifiedSeatExperience } = useContext(UserContext);

  const [hasBotEditingPermission, setHasBotEditingPermission] = useState(false);

  useEffect(() => {
    setHasBotEditingPermission(
      access.isAdmin() ||
        access.hasAnyRole('Video Admin') ||
        access.hasFeatures({
          featureName: 'company_details',
          accessLevel: 'WRITE',
        }),
    );
  }, []);

  return (
    <div className="conversation-settings-wrapper">
      <NavigationHeader
        title="Conversations"
        secondary={
          hasUnifiedSeatExperience && (
            <Breadcrumbs
              headings={[
                {
                  title: 'Video',
                  link: '/video',
                },
                {
                  title: 'Conversations',
                  link: '/settings/welcome-message',
                  isActive: true,
                },
              ]}
            />
          )
        }
      />
      <Layout className="conversation-settings">
        <BotMessageEditor
          hasBotEditing={hasBotEditing}
          hasBotEditingPermission={hasBotEditingPermission}
        />
        <ConversationPrivacy
          conversationVisibility={conversationVisibility}
          updateUserCustomizationsWrapper={updateUserCustomizationsWrapper}
        />
      </Layout>
    </div>
  );
};

export default ConversationSettings;
