import React from 'react';

import cx from 'classnames';

import {
  Breadcrumbs,
  Button,
  CardContainer,
  CircleLoader,
  H4,
  Header,
  NavigationHeader,
  Ribbon,
} from '@driftt/tide-core';

import {
  addNewCustomPrivacyOption,
  bulkAddCustomEmails,
  deleteCustomPrivacyOption,
  fetchCustomPrivacyOptionEmails,
  fetchCustomPrivacyOptions,
  fetchDefaultPrivacyOptions,
  updateCustomPrivacyOption,
  updateDefaultPrivacyOptions,
} from 'api';
import LinkPrivacySelector from 'components/linkPrivacySelector';
import PrivacyModal from 'components/privacyModal';
import { UserContext } from 'context/UserContext';

import './styles.css';

const ANYONE_WITH_LINK = -1;
const SPECIFIC_EMAILS = -2;

const initialDefaultPrivacyOptions = [
  {
    value: ANYONE_WITH_LINK,
    title: 'Anyone with the link',
    description: 'Shared videos can be viewed by anyone who receives the link.',
  },
  {
    value: SPECIFIC_EMAILS,
    title: 'People with specific email addresses',
    description: 'Shared videos can be viewed only by specified people who receive the link.',
  },
];

class PrivacySettings extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      customPrivacyOptions: [],
      videoPrivacyOptions: initialDefaultPrivacyOptions,
      isModalOpen: false,
      selectedOption: null,
      currentEditGroup: null,
    };
  }

  componentDidMount() {
    Promise.all([this.getDefaultPrivacyOptions(), this.getCustomPrivacyOptions()])
      .then(() => {
        this.setState({ loading: false });
        this.addExistingCustomPrivacyOptions();
      })
      .catch((err) => {
        Error(err);
      });
  }

  getDefaultPrivacyOptions = () => {
    return fetchDefaultPrivacyOptions()
      .then((options) => {
        let selectedOption = ANYONE_WITH_LINK;

        const teamOption = options.find((option) => option.source === 'Privacy Team');

        if (teamOption) {
          selectedOption = teamOption.value.team;
        } else if (options.length) {
          selectedOption = SPECIFIC_EMAILS;
        }

        this.setState({ selectedOption });
      })
      .catch((err) => {
        Error(err);
      });
  };

  getCustomPrivacyOptions = () => {
    return fetchCustomPrivacyOptions()
      .then((options) => {
        this.setState({ customPrivacyOptions: options });
      })
      .catch((err) => {
        Error(err);
      });
  };

  addCustomPrivacyOption = (customOption, emails) => {
    const [everyoneOption, ...restOfOptions] = this.state.videoPrivacyOptions;
    const finishedOption = {
      value: customOption.id,
      groupName: customOption.teamName,
      emailsAndDomainsList: emails,
      title: `Anyone at ${customOption.teamName} with the link`,
      description:
        'Shared videos can be viewed by anyone in the defined group who receives the link.',
      isGroup: true,
    };

    const finishedVideoPrivacyList = [everyoneOption, finishedOption, ...restOfOptions];

    this.setState({
      videoPrivacyOptions: finishedVideoPrivacyList,
    });
  };

  addExistingCustomPrivacyOptions = () => {
    return this.state.customPrivacyOptions.map((customOption) => {
      return fetchCustomPrivacyOptionEmails(customOption.id)
        .then((emails) => {
          const unformattedEmails = emails.map((email) => {
            if (email.source === 'domain') {
              return email.value.domain;
            } else {
              return email.value.email;
            }
          });
          this.addCustomPrivacyOption(customOption, unformattedEmails);
        })
        .catch((err) => {
          Error(err);
        });
    });
  };

  handleOptionChange = async (selectedOption) => {
    const hasPaidVideo = !this.context.isFreeVideoUser();
    const prevOption = this.state.selectedOption;
    const permissions = [];

    if (!hasPaidVideo) {
      return;
    }

    if (selectedOption !== ANYONE_WITH_LINK) {
      permissions.push({ source: 'self' });
    }
    if (selectedOption >= 0) {
      permissions.push({
        source: 'Privacy Team',
        value: { team: selectedOption },
      });
    }

    this.setState({ selectedOption });

    try {
      await updateDefaultPrivacyOptions({ permissions });
    } catch (error) {
      Error(error);
      this.setState({ selectedOption: prevOption });
    }
  };

  formatEmailsAndDomains = (emailsAndDomainsList) => {
    const formattedEmails = emailsAndDomainsList.map((email) => {
      if (email.charAt(0) === '@') {
        return {
          source: 'domain',
          value: {
            domain: email,
          },
        };
      } else {
        return {
          source: 'email',
          value: {
            email: email,
          },
        };
      }
    });
    return { permissions: formattedEmails };
  };

  formatCustomGroupName = (groupName) => {
    return { team_name: groupName };
  };

  addNewCustomPrivacy = (groupName, emailsAndDomains) => {
    let addedOption = null;
    addNewCustomPrivacyOption(this.formatCustomGroupName(groupName))
      .then((option) => {
        addedOption = option;
        return bulkAddCustomEmails(option.id, this.formatEmailsAndDomains(emailsAndDomains));
      })
      .then(() => {
        return fetchCustomPrivacyOptionEmails(addedOption.id);
      })
      .then(() => {
        this.setState({
          isModalOpen: false,
          currentEditGroup: null,
        });
        return this.addCustomPrivacyOption(addedOption, emailsAndDomains);
      })
      .catch((err) => {
        Error(err);
      });
  };

  updateGroupCallback = (groupId, newGroupName, newEmailsAndDomainsList) => {
    const { videoPrivacyOptions, currentEditGroup } = this.state;
    const finalPrivacyOptions = Array.from(videoPrivacyOptions);
    const optionToChange = {
      ...finalPrivacyOptions.find((option) => {
        return option.value === groupId;
      }),
    };
    const indexOfOptionToChange = finalPrivacyOptions.findIndex((option) => {
      return option.value === groupId;
    });

    return Promise.resolve()
      .then(() => {
        if (currentEditGroup.groupName !== newGroupName) {
          return updateCustomPrivacyOption(groupId, this.formatCustomGroupName(newGroupName)).then(
            () => {
              optionToChange.groupName = newGroupName;
              optionToChange.title = `Anyone at ${newGroupName} with the link`;
            },
          );
        }
      })
      .then(() => {
        if (currentEditGroup.emailsAndDomainsList !== newEmailsAndDomainsList) {
          return bulkAddCustomEmails(
            groupId,
            this.formatEmailsAndDomains(newEmailsAndDomainsList),
          ).then(() => {
            optionToChange.emailsAndDomainsList = newEmailsAndDomainsList;
          });
        }
      })
      .then(() => {
        finalPrivacyOptions[indexOfOptionToChange] = optionToChange;
        this.setState({
          videoPrivacyOptions: finalPrivacyOptions,
          isModalOpen: false,
          currentEditGroup: null,
        });
      });
  };

  deleteGroup = (group) => {
    const { videoPrivacyOptions, selectedOption } = this.state;
    const finalPrivacyOptions = Array.from(videoPrivacyOptions);
    const deleteIndex = finalPrivacyOptions.findIndex((option) => {
      return option.value === group.value;
    });

    deleteCustomPrivacyOption(group.value).then(() => {
      finalPrivacyOptions.splice(deleteIndex, 1);
      if (group.value === selectedOption) {
        this.handleOptionChange(ANYONE_WITH_LINK);
        this.setState({
          selectedOption: ANYONE_WITH_LINK,
        });
      }
      this.setState({
        videoPrivacyOptions: finalPrivacyOptions,
      });
    });
  };

  handleAddGroupClick = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  handleEditGroupClick = (group) => {
    const hasPaidVideo = !this.context.isFreeVideoUser();

    if (!hasPaidVideo) {
      return;
    } else {
      this.setState({
        isModalOpen: true,
        currentEditGroup: group,
      });
    }
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      currentEditGroup: null,
    });
  };

  render() {
    const hasPaidVideo = !this.context.isFreeVideoUser();
    const { videoPrivacyOptions, isModalOpen, currentEditGroup, selectedOption } = this.state;

    return (
      <div className="privacy-container">
        <NavigationHeader
          title="Privacy"
          secondary={
            this.context.hasUnifiedSeatExperience && (
              <Breadcrumbs
                headings={[
                  {
                    title: 'Video',
                    link: '/video',
                  },
                  {
                    title: 'Privacy',
                    link: '/settings/privacy',
                    isActive: true,
                  },
                ]}
              />
            )
          }
        />
        {this.state.loading ? (
          <CardContainer className="loading-card-container" padding="large">
            <div className="player-loader">
              <CircleLoader size="large" />
            </div>
          </CardContainer>
        ) : (
          <CardContainer padding="large">
            <Header className="settings-link-privacy-header">
              <div>
                <div
                  className={cx('header-name-and-icon', {
                    'free-plan': !hasPaidVideo,
                  })}>
                  {!hasPaidVideo && (
                    <Ribbon className="upgrade-icon" isUpgrade={true} text="upgrade" />
                  )}
                  <H4>Video privacy</H4>
                </div>
                <div>By default, who can see videos you share?</div>
              </div>
              <div>
                <Button
                  type="secondary"
                  disabled={!hasPaidVideo}
                  onClick={this.handleAddGroupClick}>
                  Add a privacy group
                </Button>
              </div>
            </Header>
            <LinkPrivacySelector
              className={cx('settings-link-privacy-selector', {
                'free-plan': !hasPaidVideo,
              })}
              parentPage="SETTINGS"
              options={videoPrivacyOptions}
              selectedOption={selectedOption}
              onChange={this.handleOptionChange}
              onEditGroupClick={this.handleEditGroupClick}
              deleteCallback={this.deleteGroup}
            />
            <PrivacyModal
              key={currentEditGroup ? currentEditGroup.value : null}
              isOpen={isModalOpen}
              addNewCustomPrivacy={this.addNewCustomPrivacy}
              updateGroupCallback={this.updateGroupCallback}
              closeModal={this.closeModal}
              editGroup={currentEditGroup}
              groupNamesList={videoPrivacyOptions.map((option) => {
                return option.groupName;
              })}></PrivacyModal>
          </CardContainer>
        )}
      </div>
    );
  }
}

export default PrivacySettings;
