import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import { UserData } from 'types/interfaces';

import {
  Breadcrumbs,
  CardContainer,
  CircleLoader,
  NavigationHeader,
  Select,
} from '@driftt/tide-core';

import { fetchStorageRegions, fetchUserInfo, updatePreferredStorageRegion } from 'api';
import { UserContext } from 'context/UserContext';
import { fetchRegionCookie } from 'utils';

import DisableCaptions from './DisableCaptions';

import './styles.css';

interface StorageOption {
  label: string;
  value: number;
}

const AccountSettings = () => {
  const { user, userHasFeature, hasUnifiedSeatExperience } = useContext(UserContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [, setUserInfo] = useState<UserData>({} as UserData);
  const [currentStorageOption, setCurrentStorageOption] = useState<StorageOption>(
    {} as StorageOption,
  );
  const [storageOptions, setStorageOptions] = useState<StorageOption[]>([]);
  const [storageId, setStorageId] = useState(-1);
  const [disableDropDown, setDisableDropdown] = useState<boolean>(false);

  function getUserInfo(userId) {
    return fetchUserInfo(userId)
      .then((userInfo) => {
        setUserInfo(userInfo);
        setFirstName(userInfo.firstName);
        setLastName(userInfo.lastName);
        setEmail(userInfo.email);
        setStorageId(userInfo.storageId);
      })
      .catch((err) => {
        Error(err);
      });
  }

  function getStorageOptions() {
    return fetchStorageRegions()
      .then((storageRegions) => {
        if (storageRegions.length > 0) {
          setStorageOptions(
            storageRegions.map((region) => {
              return {
                label: region.name,
                value: region.id,
              };
            }),
          );
        }
      })
      .catch((err) => {
        Error(err);
      });
  }

  useEffect(() => {
    if (user) {
      Promise.all([getUserInfo(user.id), getStorageOptions()]).then(() => setLoading(false));
    }
  }, [user]);

  useEffect(() => {
    const option =
      storageOptions.find((region) => {
        return region.value === storageId;
      }) || ({} as StorageOption);
    const euOption: StorageOption = {
      label: 'EU (Ireland) eu-west-1',
      value: 1,
    };
    if (fetchRegionCookie() === 'EU') {
      setCurrentStorageOption(euOption);
    } else {
      setCurrentStorageOption(option);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (fetchRegionCookie() === 'EU') {
      setDisableDropdown(true);
    }
  }, []);

  function updateSelectedStorageOption(updateOption) {
    updatePreferredStorageRegion({ storageId: updateOption.value })
      .then(() => {
        setCurrentStorageOption(updateOption);
        setStorageId(updateOption.value);
      })
      .catch((err) => {
        Error(err);
      });
  }

  return (
    <div className="account-settings-card">
      <NavigationHeader
        title="Account"
        secondary={
          hasUnifiedSeatExperience && (
            <Breadcrumbs
              headings={[
                {
                  title: 'Video',
                  link: '/video',
                },
                {
                  title: 'Account Settings',
                  link: '/settings/account',
                  isActive: true,
                },
              ]}
            />
          )
        }
      />
      {loading ? (
        <CardContainer className="loading-card-container" padding="large">
          <div className="player-loader">
            <CircleLoader size="large" />
          </div>
        </CardContainer>
      ) : (
        <CardContainer padding="large">
          <div className="name-and-email-container">
            <div className="name-container">
              <div className="header">Name</div>
              <div className="description">{firstName + ' ' + lastName}</div>
            </div>
            <div className="email-container">
              <div className="header">Email</div>
              <div className="description">{email}</div>
            </div>
          </div>
          <div className="password-container">
            <div className="header">Password</div>
            <div className="description-container description">
              <div className="description-text">You cannot change your password here.</div>
              <a
                className="description-link"
                href="https://help.salesloft.com/s/article/How-Do-I-Reset-My-Drift-Video-Password"
              >
                Learn more
              </a>
            </div>
          </div>
          {userHasFeature('HAS_USER_CAPTION_DISABLE') && (
            <>
              <div className="line-separator"></div>
              <DisableCaptions />
            </>
          )}
          <div className="line-separator"></div>
          <div className="storage-config-container">
            <div className="header">Storage region</div>
            <Select
              className="storage-options-dropdown"
              value={currentStorageOption}
              options={storageOptions}
              clearable={false}
              onChange={updateSelectedStorageOption}
              disabled={disableDropDown}
            ></Select>
          </div>
        </CardContainer>
      )}
    </div>
  );
};

export default AccountSettings;
