import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import {
  Breadcrumbs,
  CardContainer,
  NavigationHeader,
  P,
  SelectionBlocks,
} from '@driftt/tide-core';

import {
  addUserBetaFeature,
  deleteUserBetaFeature,
  fetchBetaFeaturesEnabled,
  fetchBetaFeaturesWithDetails,
} from 'api';
import { UserContext } from 'context/UserContext';

import './style.css';

interface BetaFeatureOption {
  value: string;
  title: string;
  description: string;
}

const BetaFeatures: React.FC = () => {
  const { hasUnifiedSeatExperience } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [hadError, setHadError] = useState(false);
  const [options, setOptions] = useState<BetaFeatureOption[]>([]);
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const [features, selected] = await Promise.all([
          await fetchBetaFeaturesWithDetails(),
          await fetchBetaFeaturesEnabled(),
        ]);

        const UNRENDERED_BETA_FEATURES = ['HAS_BOT_MESSAGE_EDITING', 'HAS_UNIFIED_WEBAPP'];

        const options = features
          .map(({ id, ...rest }) => ({
            value: id,
            ...rest,
          }))
          .filter((feature) => !UNRENDERED_BETA_FEATURES.includes(feature.value));

        setOptions(options);
        setSelected(selected);
        setLoading(false);
      } catch (err) {
        setHadError(true);
        Error((err as any).message);
      }
    })();
  }, []);

  const handleChange = (nextSelected) => {
    for (const id of nextSelected) {
      if (!selected.includes(id)) {
        addUserBetaFeature(id);
      }
    }

    for (const id of selected) {
      if (!nextSelected.includes(id)) {
        deleteUserBetaFeature(id);
      }
    }

    setSelected(nextSelected);
  };

  return (
    <div className="beta-features">
      <NavigationHeader
        title="Beta features"
        secondary={
          hasUnifiedSeatExperience && (
            <Breadcrumbs
              headings={[
                {
                  title: 'Video',
                  link: '/video',
                },
                {
                  title: 'Beta features',
                  link: '/settings/beta-features',
                  isActive: true,
                },
              ]}
            />
          )
        }
      />
      <CardContainer className="beta-features__content">
        {hadError ? (
          'Sorry, an error occurred.'
        ) : loading ? (
          'Loading...'
        ) : (
          <SelectionBlocks
            header={
              <P className="beta-features__description">
                These features are still undergoing final testing before official release. The
                website, its software and all its content found on it are provided on an “as is” and
                “as available” basis. Drift Video does not give any warranties, whether express or
                implied, as to the suitability or usability of the website, its software or any of
                its content. Drift Video reserves the right to convert any Experimental or BETA
                features into paid features or to remove them entirely.
              </P>
            }
            type="checkbox"
            options={options}
            selected={selected}
            onChange={handleChange}
          />
        )}
      </CardContainer>
    </div>
  );
};

export default BetaFeatures;
