import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import infoIcon from 'assets/svg/info-icon-2.svg';
import cx from 'classnames';
import { EducationItem, NotifSettings } from 'types/interfaces';

import {
  A,
  Breadcrumbs,
  CardContainer,
  Div,
  H4,
  H5,
  InlineWarning,
  Input,
  Label,
  NavigationHeader,
  P,
} from '@driftt/tide-core';

import { fetchEducationContent, markAsComplete } from 'api';
import { UserContext } from 'context/UserContext';
import { CHAT_APP_URL, popUp } from 'utils';
import { segmentPage, segmentTrack } from 'utils/metrics';

import './styles.css';

interface NotificationSettingsProps {
  notificationSettings: NotifSettings;
  updateUserCustomizationsWrapper: ({
    notificationSettings,
  }: UpdateUserCustomizationPayload) => Promise<void>;
}

interface UpdateUserCustomizationPayload {
  notificationSettings: NotifSettings;
}

const NOTIFICATION_SETTINGS_TYPES = {
  EMAIL: 'email',
  PUSH_NOTIFICATIONS: 'pushNotifications',
  TEAM_NOTIFICATIONS: 'teamNotifications',
  WEEKLY_ADMIN: 'weeklyAdmin',
};

const DEFAULT_PUSH_NOTIFICATION_OPTIONS = [
  {
    type: NOTIFICATION_SETTINGS_TYPES.PUSH_NOTIFICATIONS,
    title: 'All video views',
    description: 'Get notified when a viewer starts viewing your video.',
  },
];

const TEAM_NOTIFICATION_OPTIONS = [
  {
    type: NOTIFICATION_SETTINGS_TYPES.PUSH_NOTIFICATIONS,
    title: 'Video views from outside of my team',
    description:
      'Get notified when a viewer who is not part of your team starts viewing your video.',
  },
  {
    type: NOTIFICATION_SETTINGS_TYPES.TEAM_NOTIFICATIONS,
    title: 'Video views from my team',
    description: 'Include views from your teammates using Drift Video.',
  },
];

type CheckboxProps = {
  type?: string;
  title?: string;
  description?: string;
  checked?: boolean;
  onToggle: any;
  value: boolean;
  disabled?: boolean;
};

const CheckboxContainer = ({
  onToggle,
  value,
  type,
  title,
  description,
  disabled = false,
}: CheckboxProps) => (
  <Div
    onClick={() => !disabled && onToggle(type, value)}
    className={cx('settings-checkbox-input', {
      'settings-checkbox-input--checked': value,
      disabled: disabled,
    })}>
    <Input type="checkbox" checked={value} disabled={disabled} />
    <Label>
      <P className="settings-option-title">{title}</P>
      <P className="tide-paragraph tide-paragraph-2">{description}</P>
    </Label>
  </Div>
);

const NotificationSettings = ({
  notificationSettings,
  updateUserCustomizationsWrapper,
}: NotificationSettingsProps) => {
  const { userHasFeature, hasChatSeat, hasUnifiedSeatExperience } = useContext(UserContext);
  const [pushNotificationOptions, setPushNotificationOptions] = useState(
    DEFAULT_PUSH_NOTIFICATION_OPTIONS,
  );
  const [settings, setSettings] = useState<NotifSettings>(notificationSettings);
  const [notificationAlert, setNotificationAlert] = useState<EducationItem | null>(null);
  const [canShowNotificationAlert, setCanShowNotificationAlert] = useState<boolean>(true);

  const hasTeamNotifications = userHasFeature('HAS_TEAM_NOTIFICATIONS');

  useEffect(() => {
    segmentPage('Settings Page Viewed', { page: 'notifications' });

    if (hasTeamNotifications) {
      setPushNotificationOptions(TEAM_NOTIFICATION_OPTIONS);
    }

    fetchEducationContent('SETTINGS').then(({ data }) => {
      const maybeNotificationAlert = data.find((item) => {
        return item.location === 'SETTINGS' && item.name === 'no_channels_notified_alert';
      });

      setNotificationAlert(maybeNotificationAlert);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function toggleNotificationSetting(settingName: string, prevValue: boolean) {
    const newSettings = settings;
    newSettings[settingName] = !prevValue;

    if (
      settingName === NOTIFICATION_SETTINGS_TYPES.PUSH_NOTIFICATIONS &&
      !newSettings.pushNotifications
    ) {
      newSettings.teamNotifications = false;
    }

    updateUserCustomizationsWrapper({ notificationSettings: newSettings }).then(() =>
      setSettings(newSettings),
    );
  }

  function dismissNotificationEducation() {
    if (notificationAlert) {
      markAsComplete({
        id: notificationAlert.id,
        cascade_name: notificationAlert.name,
      }).then(() => setCanShowNotificationAlert(false));
    }
  }

  function handleNotificationWarningClick() {
    segmentTrack('Notification Inline Warning Clicked');
    // @ts-ignore
    popUp({
      path: `${CHAT_APP_URL}/settings2/notifications/video`,
      windowName: 'Drift_Video_configure_browser_notifications',
      onClose: dismissNotificationEducation,
    });
  }

  return (
    <div className="notifications-container">
      <NavigationHeader
        title="Video notifications"
        secondary={
          hasUnifiedSeatExperience && (
            <Breadcrumbs
              headings={[
                {
                  title: 'Video',
                  link: '/video',
                },
                {
                  title: 'Video notifications',
                  link: '/settings/notifications',
                  isActive: true,
                },
              ]}
            />
          )
        }
      />
      <CardContainer padding="large">
        <H4 className="card-title">Push notifications</H4>
        <H5>Video</H5>
        {!!notificationAlert && canShowNotificationAlert && (
          <InlineWarning
            title="Your desktop notifications are not set up properly"
            type="warning"
            copy="We couldn’t detect your permissions for desktop notifications. Without enabling them, Drift Video wont be able to notify you of new views or conversations."
            onClick={handleNotificationWarningClick}
            buttonText="Set up notifications"
          />
        )}

        {pushNotificationOptions.map((option, index) => {
          return (
            <CheckboxContainer
              key={`${option.type}-${index}`}
              value={settings[option.type] ?? true}
              type={option.type}
              onToggle={toggleNotificationSetting}
              title={option.title}
              description={option.description}
              disabled={
                option.type === NOTIFICATION_SETTINGS_TYPES.TEAM_NOTIFICATIONS &&
                settings[NOTIFICATION_SETTINGS_TYPES.PUSH_NOTIFICATIONS] === false
              }
            />
          );
        })}
        <div className="alert-message">
          <img className="alert-icon" src={infoIcon} alt="infoIcon" />
          <div className="alert-text">
            You must have the Drift app on iOS to receive push notifications on your mobile devices
          </div>
        </div>
        {hasChatSeat() && (
          <>
            <H5>Conversations</H5>
            <P className="conversation-settings-subcopy">
              Get notified when a viewer starts a conversation on your video.&nbsp;
              <A href={`${CHAT_APP_URL}/settings2/notifications`} target="_blank">
                Customize
              </A>
            </P>
          </>
        )}
      </CardContainer>
      <CardContainer padding="large">
        <H4 className="card-title">Email notifications</H4>
        <H5>Video</H5>
        <CheckboxContainer
          value={settings?.email ?? true}
          type={NOTIFICATION_SETTINGS_TYPES.EMAIL}
          onToggle={toggleNotificationSetting}
          title={'Completed video views'}
          description={
            'Get notified when a viewer finishes viewing your video to see the percentage of the video watched.'
          }
        />
        {userHasFeature('WEEKLY_TEAM_STATS_EMAIL') && (
          <CheckboxContainer
            value={settings?.weeklyAdmin ?? true}
            type={NOTIFICATION_SETTINGS_TYPES.WEEKLY_ADMIN}
            onToggle={toggleNotificationSetting}
            title={'Weekly Performance Report'}
            description={
              'A weekly summary of the number of videos you and your teammates are creating, including the number of views on those videos.'
            }
          />
        )}
        {hasChatSeat() && (
          <>
            <H5>Conversations</H5>
            <P className="conversation-settings-subcopy">
              Get notified when a viewer starts a conversation on your video whenever you miss a
              push notification.&nbsp;
              <A href={`${CHAT_APP_URL}/settings2/notifications/advanced`} target="_blank">
                Customize
              </A>
            </P>
          </>
        )}
      </CardContainer>
    </div>
  );
};

export default NotificationSettings;
