/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import * as debounce from 'lodash.debounce';

import {
  A,
  Button,
  CardContainer,
  Div,
  H4,
  Loader,
  P,
  Ribbon,
  SplitWidthContainer,
} from '@driftt/tide-core';
import { Driftiwyg } from '@driftt/tide-driftiwyg';

import { fetchBotMessage, updateBotMessage } from 'api';
import { openTeamsUpgradePlaybook } from 'utils';

import BotPreview from './BotPreview/BotPreview';

interface EditorButtonsProps {
  saving: boolean;
  hasEdited: boolean;
  saveBotMessageData: () => void;
  cancelBotEditing: () => void;
}

const EditorButtons: React.FC<EditorButtonsProps> = ({
  saving,
  hasEdited,
  saveBotMessageData,
  cancelBotEditing,
}) => (
  <Div className="button-wrapper">
    <Button type="primary" loading={saving} disabled={!hasEdited} onClick={saveBotMessageData}>
      Save
    </Button>
    <Button type="tertiary" disabled={!hasEdited} onClick={cancelBotEditing}>
      Cancel
    </Button>
  </Div>
);

const BotMessageEditor: React.FC<{ hasBotEditing: boolean; hasBotEditingPermission: boolean }> = ({
  hasBotEditing,
  hasBotEditingPermission,
}) => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [hasEdited, setHasEdited] = useState(false);
  const originalMessage = useRef('');
  const [currentVideoMessage, setCurrentVideoMessage] = useState('');
  const [cancelKey, setCancelKey] = useState(0);

  const fetchBotMessageData = useCallback(async () => {
    try {
      const botMessage = await fetchBotMessage();
      setCurrentVideoMessage(botMessage);
      originalMessage.current = botMessage;
      setLoading(false);
    } catch (e) {
      Error((e as any).message);
    }
  }, []);

  const saveBotMessageData = useCallback(async () => {
    setSaving(true);
    try {
      await updateBotMessage(currentVideoMessage, 2);
      setHasEdited(false);
      originalMessage.current = currentVideoMessage;
    } catch (e) {
      Error((e as any).message);
    }
    setSaving(false);
  }, [currentVideoMessage]);

  const cancelBotEditing = () => {
    setCancelKey(cancelKey + 1);
    setCurrentVideoMessage(originalMessage.current);
    setHasEdited(false);
  };

  useEffect(() => {
    fetchBotMessageData();
  }, []);

  const handleMessageUpdate = debounce((newMessage) => {
    if (!hasEdited && newMessage !== originalMessage.current) {
      setHasEdited(true);
    }
    setCurrentVideoMessage(newMessage);
  }, 100);

  const shouldDisableEditing = !hasBotEditing || !hasBotEditingPermission;

  return (
    <CardContainer padding="large" className="bot-message-card-container">
      <SplitWidthContainer>
        {loading ? (
          <Loader />
        ) : (
          <Div className="bot-message-editor">
            {!hasBotEditing && <Ribbon isUpgrade={true} text="upgrade" />}
            <H4>Video bot message</H4>
            {hasBotEditing && hasBotEditingPermission ? (
              <P>This message is displayed in all video chats within your organization.</P>
            ) : (
              <Div className="upgrade-copy">
                <P>
                  Customize the way the bot starts conversations in videos within your organization.
                </P>
                {hasBotEditingPermission ? (
                  <A onClick={openTeamsUpgradePlaybook} type="secondary">
                    Chat with your account manager to upgrade
                  </A>
                ) : (
                  <P>Contact your admin to modify the message.</P>
                )}
              </Div>
            )}

            <Driftiwyg
              readOnly={shouldDisableEditing}
              key={cancelKey}
              autoFocus={true}
              editorState={originalMessage.current}
              onChange={handleMessageUpdate}
              toolbarOptions={[
                'bold',
                'italic',
                'underlined',
                'numbered-list',
                'bulleted-list',
                'link',
                'emoji',
              ]}
              v2
            />
            {hasBotEditing && hasBotEditingPermission && (
              <EditorButtons
                saving={saving}
                hasEdited={hasEdited}
                saveBotMessageData={saveBotMessageData}
                cancelBotEditing={cancelBotEditing}
              />
            )}
          </Div>
        )}
        <Div>
          <BotPreview message={currentVideoMessage} showBookMeetingButton={false} />
        </Div>
      </SplitWidthContainer>
    </CardContainer>
  );
};

export default BotMessageEditor;
